.tap input[type="text"]{
    color: inherit !important;
}
.tap-header, .tap-newsletter{
    background:url('./assets/images/hero-bg.png');
    background-size:100% 100%;
    background-repeat:no-repeat;
    background-position:center
}
.tap-newsletter{
    background:url('./assets/images/form-bg.png');
    background-size:100% 100%;
    background-repeat:no-repeat;
    background-position:center
}
.tap-footer-social{
    /* background:url('./assets/images/footer-bg.png'); */

}


@media (prefers-color-scheme: dark) {
    /* Dark mode styles */
    .tap input[type="text"]{
        color: inherit !important;
        background-color: white;
    }
    .tap-header {
      background-image: url('./assets/images/hero-bg-black.png');
    }
    .tap-newsletter{
        background-image: url('./assets/images/form-bg-dark.png');

    }
    .tap-footer-social{
        background:url('./assets/images/footer-bg-dark.png');
    
    }
    
  }

  @media screen and  (max-width:600px)  {
    .tap-header{
        background-image: url('./assets/images/hero-mobile-bg.png');
    }
  }

  @media screen and  (max-width:600px) and  (prefers-color-scheme: dark) {
    .tap-header{
        background-image: url('./assets/images/hero-mobile-dark.png');
    }
  }